<template>
  <div class="class_team_navbar">
    <!-- <div
      class="class_team_navbar_tab"
      :class="navActive === 1 ? 'active' : navActive === 2 ? 'activeb' : ''"
      @click="navclick(1, 'day')"
    >
      日期排序
    </div> -->
    <div class="class_team_navbar_tab" @click="navclick(0)">
      默认排序
    </div>
    <div
      class="class_team_navbar_tab"
      :class="
        sortType === 3 ? 'active' : sortType === 4 ? 'activeb' : 'activen'
      "
      @click="navclick(3)"
    >
      消费排序
    </div>
    <div
      class="class_team_navbar_tab"
      :class="
        sortType === 5 ? 'active' : sortType === 6 ? 'activeb' : 'activen'
      "
      @click="navclick(5)"
    >
      献佣排序
    </div>
    <!-- <div
      class="class_team_navbar_tab class_filter"
      :class="filterN ? 'activef' : ''"
      @click="navclick(9)"
    >
      筛选
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'TeamNavbar',
  props: {
    sortType: {
      type: Number,
      default: 0
    },
    filter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      navActive: this.sortType,
      navActiveN: this.sortType,
      flags: {
        day: false,
        con: false,
        opt: false
      }
    }
  },
  computed: {
    filterN() {
      return this.filter
    }
  },
  watch: {
    sortType: {
      handler(newval) {
        this.navActive = newval
      },
      immediate: true
    }
  },
  methods: {
    navclick(i) {
      if (i === 9) {
        this.$emit('nav-click', i)
      }
      // if (flag === '') {
      //   this.navActiveN = i
      //   this.$emit('nav-click', this.navActiveN)
      // } else {
      //   this.flags[flag] = !this.flags[flag]
      //   this.navActiveN = i + (this.flags[flag] ? 0 : 1)
      //   this.$emit('nav-click', this.navActiveN)
      // }
      switch (i) {
        case 3:
          if (this.sortType === 3) this.$emit('nav-click', 4)
          else this.$emit('nav-click', 3)
          break
        case 5:
          if (this.sortType === 5) this.$emit('nav-click', 6)
          else this.$emit('nav-click', 5)
          break
        default:
          this.$emit('nav-click', i)
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
.class_team_navbar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 3px;
  font-family: Source Han Sans CN;
  font-size: 14px;
  background-color: #ffffff;
  &_tab {
    flex: 3;
    padding: 3px 10px;
    text-align: center;
  }
  .class_filter {
    flex: 0 0 76px;
    border-left: 1px solid #e8e8e8;
  }
  .activef {
    color: red;
  }
  .active::after {
    display: inline-block;
    width: 10px;
    height: 10px;
    content: '';
    background: url(../../assets/sort-up.png) no-repeat;
    background-size: 10px;
  }
  .activeb::after {
    display: inline-block;
    width: 10px;
    height: 10px;
    content: '';
    background: url(../../assets/sort-down.png) no-repeat;
    background-size: 10px;
  }
  .activen::after {
    display: inline-block;
    width: 10px;
    height: 10px;
    content: '';
    background: url(../../assets/sort-order.png) no-repeat;
    background-size: 10px;
  }
}
</style>
