<template>
  <div class="item_card_H_wrap" @click="OnClick">
    <div class="item_icon">
      <van-image :src="tavatar" class="item_icon_avatar" @error="imgerror" />
    </div>
    <div class="item_desc">
      <div class="item_desc_main">
        <span class="item_desc_main_name">{{ nickname }}</span>
        <span class="item_desc_main_amount">消费：￥{{ cost }}</span>
      </div>
      <div class="item_desc_sub">
        <span class="item_desc_sub_type">{{ levelc }}</span>
        <span class="item_desc_sub_brokerage">献佣：￥{{ commission }}</span>
      </div>
      <div class="item_desc_sub2">
        <span class="item_desc_sub_jointime">加入日期：{{ time }}</span>
      </div>
      <div class="item_desc_sub3">
        <span class="item_desc_sub_jointime">{{ num }}笔订单</span>
      </div>
    </div>
  </div>
</template>

<script>
import defaultperson from '../../assets/avatar_default.png'
import { Image } from 'vant'
export default {
  name: 'ItemCardPersonDetail',
  components: {
    [Image.name]: Image
  },
  props: {
    nickname: {
      type: String,
      default: '无名氏'
    },
    avatar: {
      type: String,
      default: defaultperson
    },
    commission: {
      type: String,
      default: '0.00'
    },
    cost: {
      type: String,
      default: '0.00'
    },
    level: {
      type: String,
      default: '代理商'
    },
    time: {
      type: String,
      default: '2020-01-01'
    },
    num: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      defaultperson,
      tavatar: this.avatar
    }
  },
  computed: {
    namec() {
      return this.name
    },
    levelc() {
      return this.level === null ? '分销商' : this.level
    }
  },
  methods: {
    OnClick() {
      this.$emit('click')
    },
    imgerror() {
      console.log('imgerror')
      this.tavatar = defaultperson
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.item_card_H_wrap {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 10px;
  margin: 1px;
  background-color: #ffffff;
}
.item_sn {
  width: 27px;
  height: 24px;
  line-height: 27px;
  text-align: center;
}
.item_icon {
  position: relative;
  width: 60px;
  height: 60px;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  &_avatar {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
  }
}
.item_desc {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  margin-left: 20px;
  &_main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: @font-size-normal;
    &_name {
      font-weight: bold;
    }
  }
  &_sub {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: @font-size-small;
  }
  &_sub2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: @font-size-small;
  }
  &_sub3 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    font-size: @font-size-normal;
    color: rgb(48, 174, 100);
  }
}
</style>
