<template>
  <div class="item_list over-hide">
    <form action="/search">
      <van-search
        v-model="searchVal"
        placeholder="请输入微信昵称、手机号"
        :show-action="showAction"
        @input="onInput"
        @search="onSearch"
        @cancel="onCancel"
      >
        <template #action>
          <div class="item_list_search_btn">
            <div v-if="showCancel" @click="onCancel">
              取消
            </div>
            <div v-else @click="onSearch(searchVal)">
              搜索
            </div>
          </div>
        </template>
      </van-search>
    </form>
    <team-navbar :sort-type="sortType" :filter="filter" @nav-click="navclick" />
    <van-pull-refresh
      v-model="refreshing"
      class="fixheight"
      @refresh="onRefresh"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        offset="100"
        @load="onListLoad"
      >
        <item-card-person-detail
          v-for="(item, index) in items"
          :key="index"
          :nickname="item.nickname"
          :avatar="item.head_pic"
          :level="item.level"
          :num="item.num"
          :time="item.time"
          :commission="item.commission"
          :cost="item.cost"
        />
        <!-- <van-empty description="内容为空" /> -->
      </van-list>
    </van-pull-refresh>
    <van-popup
      v-model="popupshow"
      position="right"
      :style="{ height: '100%', width: '70%' }"
    >
      <popup-btn @onPpReset="onPpReset" @onPpConfirm="onPpConfirm">
        <van-dropdown-menu>
          <van-dropdown-item v-model="value1" :options="option1" />
        </van-dropdown-menu>
        <van-dropdown-menu>
          <van-dropdown-item v-model="value2" :options="option2" />
        </van-dropdown-menu>
      </popup-btn>
    </van-popup>
  </div>
</template>

<script>
import {
  List,
  NoticeBar,
  Cell,
  Search,
  Tab,
  Tabs,
  Popup,
  Empty,
  DropdownMenu,
  PullRefresh,
  CellGroup,
  DropdownItem,
  Toast
} from 'vant'
import teamNavbar from './team-navbar'
import itemCardPerson from '../../components/item-card-person'
import ItemCardGoods from '../../components/item-card-goods'
import ItemCardPersonDetail from '../../components/item-card-person-detail'
import PopupBtn from '../../components/PopupBtn'
import asyncwait from '@/mixins/asyncwait'
import handleNetError from '@/mixins/checklogin'
// eslint-disable-next-line no-unused-vars
import { teamMember } from '@/api/team'
export default {
  name: 'Team',
  components: {
    [teamNavbar.name]: teamNavbar,
    [List.name]: List,
    [Cell.name]: Cell,
    [Empty.name]: Empty,
    [CellGroup.name]: CellGroup,
    [NoticeBar.name]: NoticeBar,
    [PullRefresh.name]: PullRefresh,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Search.name]: Search,
    [Popup.name]: Popup,
    [itemCardPerson.name]: itemCardPerson,
    [ItemCardGoods.name]: ItemCardGoods,
    [ItemCardPersonDetail.name]: ItemCardPersonDetail,
    [PopupBtn.name]: PopupBtn,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem
  },
  mixins: [asyncwait, handleNetError],
  data() {
    return {
      transitionName: 'van-slide-right',
      searchVal: '',
      showCancel: false,
      popupshow: false,
      loading: false,
      error: false,
      refreshing: false,
      finished: false,
      items: [],
      sortType: 0,
      filter: false,
      showAction: false,
      sort: '',
      keyword: {},
      pageIndex: 0,
      value1: 0,
      value2: 'a',
      option1: [
        { text: '全部商品', value: 0 },
        { text: '新款商品', value: 1 },
        { text: '活动商品', value: 2 }
      ],
      option2: [
        { text: '默认排序', value: 'a' },
        { text: '好评排序', value: 'b' },
        { text: '销量排序', value: 'c' }
      ]
    }
  },
  created() {},
  methods: {
    navclick(e) {
      console.log(e)
      if (this.loading) return
      if (e === 9) {
        this.popupshow = true
        return
      }
      if (this.sortType === e) {
        return
      }
      this.sortType = e
      switch (e) {
        case 3:
          this.sort = 'cost_up'
          break
        case 4:
          this.sort = 'cost_down'
          break
        case 5:
          this.sort = 'commission_up'
          break
        case 6:
          this.sort = 'commission_down'
          break
        default:
          this.sort = ''
          break
      }
      this.onRefresh()
    },
    onPpReset() {
      this.popupshow = false
      this.filter = false
      console.log('onPpReset')
    },
    onPpConfirm() {
      this.popupshow = false
      this.filter = true
      console.log('onPpConfirm')
    },
    onInput(keyword) {
      this.showCancel = false
      if (keyword === '') this.showAction = true
      else this.showAction = true
    },
    onSearch(keyword) {
      console.log('onSearch')
      if (keyword === '') {
        Toast('请输入关键字来搜索')
        return
      }
      this.showCancel = true
      console.log(keyword)
      this.showAction = true
      this.keyword = { keyword: keyword }
      this.onRefresh()
    },
    onCancel(keyword) {
      console.log('onCancel')
      console.log(keyword)
      this.showAction = false
      this.keyword = {}
      this.showCancel = false
      this.onRefresh()
    },
    async onRefresh() {
      // 清空列表数据
      this.items = []
      this.finished = false
      this.refreshing = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.pageIndex = 1
      await this.onListLoad()
    },
    async onListLoad() {
      console.log('callload')
      await this.asyncWait(300)
      // setTimeout(() => {
      //   for (let i = 0; i < 2; i++) {
      //     this.items.push(this.items.length + 1)
      //   }

      //   // 加载状态结束
      //   this.loading = false
      //   console.log(this.items.length)
      //   // 数据全部加载完成
      //   if (this.items.length >= 30) {
      //     this.finished = true
      //   }
      // }, 1000)
      try {
        const res = await teamMember({
          pageIndex: this.pageIndex,
          pageSize: 10,
          sort: this.sort,
          ...this.keyword
        })
        if (res.status !== 1) throw res.msg
        this.pageIndex++
        this.items = this.items.concat(res.data)
        this.loading = false
        if (res.data.length < 10) {
          this.finished = true
        }
        console.log(res)
        console.log('teamMember cp')
      } catch (err) {
        this.handleNetError(err)
        this.loading = false
        this.error = true
        console.log(err)
      }
    }
  }
}
</script>

<style scoped>
.fixheight {
  height: calc(100vh - 54px - 45px - 50px);
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
